@import "@/styles/variables";
@import "@/styles/mixins";

.centerOnSmallScreens {
  text-align: center;
}

.heroSection {
  @extend .centerOnSmallScreens;
  height: calc(100vh - $navbar-height);
  color: #ffffff;
  overflow: hidden;

  .topBackgroundImage {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: brightness(90%);
  }

  h1 {
    padding: 20vh 24px 16px 24px;
  }

  .scrollDownButton {
    $size: 48px;
    position: absolute;
    left: calc(50% - $size / 2);
    bottom: 32px;
    animation-name: bounce;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    animation-delay: 4s;

    button {
      border: none;

      img {
        width: 48px;
        height: 48px;
        /* svg color: #FFFFFF */
        /* REF: https://codepen.io/sosuke/pen/Pjoqqp */
        filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(175deg)
        brightness(104%) contrast(101%);
      }
    }
  }
}

@keyframes bounce {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-12px);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes bounceHorizontal {
  0% {
    transform: translateX(-6px);
  }
  50% {
    transform: translateX(6px);
  }
  100% {
    transform: translateX(-6px);
  }
}


@include desktop {
  .centerOnSmallScreens {
    text-align: left;
  }

  .heroSection {
    h1 {
      padding: 20vh 0px 32px 0px;
      max-width: 70vw;
    }

    .title {
      padding-top: 5vh;
    }
  }
}
